.section {
  background-color: black;
  padding-top: 200px;
  padding-bottom: 50px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;

  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 50px;
  row-gap: 40px;

  position: relative;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);

    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 768px) {
    column-gap: 20px;
    row-gap: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo {
  position: relative;
  grid-area: 1/1/4/2;

  @media (max-width: 1150px) {
    grid-area: 2/1/4/2;
    align-self: center;
    justify-self: flex-start;
    height: 100%;
    width: 100%;
  }

  & > img {
    position: absolute;
    bottom: -100px;
    right: 0;

    @media (max-width: 1150px) {
      width: 250px;
      bottom: 0;
      top: 0;
      right: unset;
      left: -20%;
    }

    @media (max-width: 768px) {
      width: 250px;
      bottom: unset;
      top: 0;
      // left: calc(-60% - 80px);
      left: unset;
      right: 10%;
    }
  }
}

.title {
  grid-area: 1/2/2/4;
  font-family: Geometria;
  color: white;
  font-weight: 600;
  font-size: 35px;
  line-height: 1;

  margin-top: 0;

  @media (max-width: 1150px) {
    grid-area: 1/1/2/3;
  }

  @media (max-width: 768px) {
    font-size: 25px;
  }

  & > span {
    font-weight: 300;
  }
}

.text {
  grid-area: 2/2/3/4;
  color: white;
  font-size: 14px;

  @media (max-width: 1150px) {
    grid-area: 2/2/3/3;
  }
}

.list {
  grid-area: 3/2/4/4;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  list-style: none;

  @media (max-width: 1150px) {
    grid-area: 3/2/4/3;
  }

  @media (max-width: 768px) {
    grid-area: 3/2/4/3;
    grid-template-columns: 1fr;
  }

  & > li {
    color: white;
    font-size: 14px;

    background-color: #4f4898;
    padding: 10px;
    border-radius: 15px;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 10px;
    }

    &::before {
      content: '— ';
    }
  }
}

.order {
  grid-area: 1/4/4/5;
  align-self: flex-end;
  background-color: #4f4898;
  padding: 15px 25px 15px 100px;
  position: relative;
  border-radius: 50px;

  font-family: Geometria;
  font-size: 20px;
  line-height: 1.5;
  color: white;

  @media (max-width: 1150px) {
    grid-area: 4/1/5/3;
  }

  @media (max-width: 768px) {
    width: min-content;
    justify-self: center;
  }

  & > img {
    position: absolute;
    left: 15px;
    bottom: -5px;
  }
}

.arrow {
  position: absolute;
  top: 200px;
  right: 20%;

  @media (max-width: 1150px) {
    right: 30%;
    rotate: 110deg;
  }

  @media (max-width: 768px) {
    top: unset;
    bottom: 50%;
    right: 20px;
  }
}
