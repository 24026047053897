.header {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1100px;
  margin: 0 auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 0 40px;
  background-color: transparent;

  transition: all 0.3s ease;
  will-change: padding;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -1000px;
    right: -1000px;

    height: 100%;
  }

  &__black {
    &::before {
      background: rgba(black, 0.8);
    }

    a:first-child {
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  &__white {
    &::before {
      background: rgba(white, 0.8);
    }

    a:first-child {
      svg {
        path {
          fill: black;
          transition: fill 0.3s ease;
        }
      }
    }
  }

  &__scroll {
    padding: 25px 0 20px;
  }
}

.navLinks {
  display: flex;
  align-items: center;

  a {
    position: relative;
    z-index: 5;
    margin: 0 17px;

    font-size: 23px;
    font-weight: 300;
    text-decoration: none;
    text-wrap: nowrap;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -3px;
      top: 3px;
      z-index: -1;
      display: block;
      width: 11px;
      height: 11px;
      background-color: #d6338a;

      transition: height 0.4s ease, left 0.4s ease, width 0.4s ease;
      will-change: left, height, width;
    }

    &:hover {
      &::before,
      &::after {
        width: 2px;
        height: 25px;
        left: -10px;
      }
    }

    &.pressed {
      &::before {
        width: 2px;
        height: 25px;
        left: -10px;
      }
      &::after {
        width: 2px;
        height: 25px;
        left: calc(100% + 10px);
      }
    }
  }

  &__white {
    a {
      color: #50535a;
    }
  }

  &__black {
    a {
      color: white;
    }
  }
}

// выезжающая панель бургера
.panel {
  display: block;
  position: fixed;
  top: 0;
  right: 50%;
  z-index: 4999;
  height: 100vh;
  width: 100vw;
  padding: 10px 20px;
  box-sizing: border-box;

  transform: translateX(50%);
  transition: all 0.3s ease;

  backdrop-filter: blur(3px);

  &__hide {
    opacity: 0;
    pointer-events: none;
  }
}

.overlay {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(black, 0);
}

.panel_content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(white, 0) 0%, rgba(white, 0.8) 100%);
  padding-right: 100px;
  box-sizing: border-box;

  transition: all 0.3s ease;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  &__hide {
    opacity: 0;
  }

  &__dark {
    background: linear-gradient(90deg, rgba(black, 0) 0%, rgba(black, 0.8) 100%);

    a {
      color: white;

      &:hover {
        color: #d6338a;
      }
    }
  }

  &__white {
    a {
      color: #50535a;
      &:hover {
        color: #d6338a;
      }
    }
  }

  a {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-decoration: none;
    transition: color 0.3s ease;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}
