.section {
  background-color: black;
  color: white;
  padding-bottom: 100px;

  background-image: url('../../../../assets/img/brandBackground.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;

  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
}

.title {
  position: relative;
  margin: 0 auto;
  width: min-content;
  background-color: #d6338a;
  border-radius: 50px;

  padding: 18px 25px 18px 110px;

  font-family: Geometria;
  font-size: 20px;
  font-weight: 300;

  & > img {
    position: absolute;
    top: -15px;
    left: 15px;

    width: 80px;
  }

  & > span {
    text-wrap: nowrap;
  }
}

.form {
  background-color: #fff;
  opacity: 0.9;

  color: black;
  padding: 20px 40px;
  margin-top: 60px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }

  & > p {
    text-transform: uppercase;
    letter-spacing: 1rem;
    font-weight: 600;
    background-color: black;
    color: white;
    padding: 10px 5px 10px 20px;
    border-radius: 20px;

    @media (max-width: 768px) {
      letter-spacing: 0.5rem;
      text-align: center;
    }
  }

  & > button {
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    text-decoration: underline;
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  row-gap: 10px;
  list-style: none;

  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
}

.item {
  &__button {
    grid-column: -1/-2;

    & > button {
      background-color: #4f4c93;
      border: none;
      border-radius: 50px;
      width: 100%;

      padding-top: 10px;
      padding-bottom: 10px;

      color: white;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  & > label {
    width: 100%;
    display: block;
    height: 100%;

    & > span {
      display: block;
    }

    & > input,
    & > textarea {
      border: 1px solid lightgray;
      width: 100%;
      border-radius: 5px;
      padding: 5px 10px;
      box-sizing: border-box;

      &:focus {
        border: 1px solid #4f4c93;
        outline: 1px solid #4f4c93;
      }
    }
  }
}

.bottom {
  margin-top: 15px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;

  height: 100vh;
  width: 100vw;

  overflow-y: auto;
  padding: 50px 0;
  pointer-events: auto;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;

  @media (max-width: 450px) and (max-height: 700px) {
    padding-top: 200px;
  }
}

.modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: min-content;
  margin: auto 0;
  position: relative;
  z-index: 200001;
  border-radius: 20px;
  color: black;
  gap: 10px;
  background: linear-gradient(
    196deg,
    rgba(255, 255, 255, 0.9) -18.74%,
    rgba(255, 255, 255, 0.83) 41.7%,
    rgba(255, 255, 255, 0) 142.18%
  );
  padding: 20px;
  max-width: 650px;

  & > div {
    width: 100%;
  }
}

.successTitle {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}

.closeButton {
  background-color: transparent;
  border: none;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-decoration: underline;
}

.invalidMessage {
  background-color: red;
  color: white;
  border: none;
  border-radius: 50px;
  line-height: 2.5;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 100%;
    display: block;
    box-sizing: border-box;
    text-align: center;
    font-weight: 500;
    line-height: unset;
  }
}
