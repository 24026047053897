.wrapper {
  background-color: #fff;
  opacity: 0.9;

  color: black;
  padding: 20px 40px;
  margin-top: 60px;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 30px;
  }
}

.title {
  text-transform: uppercase;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.list {
  list-style: none;

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  & > li {
    padding: 20px 0;
    position: relative;

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 15px 0;
    }

    &:last-child {
      grid-area: 1/2/3/3;

      @media (max-width: 768px) {
        grid-area: unset;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: black;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }
}

.link {
  color: #4f4c93;
}
